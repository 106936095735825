import React from 'react'

import { SimulationLayout } from '@presentation/containers'
import { CalculatorProvider } from '@presentation/providers/CalculatorProvider'
import { SimulationProvider } from '@presentation/providers/SimulationProvider'

import { makeMaterialLayout } from '../material'

export const makeSimulationLayout = (page: React.ReactElement) => {
  return makeMaterialLayout(
    <SimulationProvider>
      <SimulationLayout>{page}</SimulationLayout>
    </SimulationProvider>,
  )
}

export const makeCalculatorLayout = (page: React.ReactElement) => {
  return makeSimulationLayout(<CalculatorProvider>{page}</CalculatorProvider>)
}

export const makeResumeLayout = (page: React.ReactElement) => {
  return makeCalculatorLayout(page)
}
