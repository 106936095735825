import React from 'react'

import { NotifyProvider, ThemeProvider } from '@azos/shared'
import { Notification } from '@presentation/components'
import { CallpageContainer } from '@presentation/containers'

export const makeMaterialLayout = (page: React.ReactElement) => (
  <ThemeProvider>
    <NotifyProvider>
      <CallpageContainer />
      <Notification />
      {page}
    </NotifyProvider>
  </ThemeProvider>
)
